import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fileAbsolutePath: { regex: "/team/" } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            id
            frontmatter {
              name
              description
              position
              date(formatString: "MMMM DD, YYYY")
              coverImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              profileImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Us" />
      <Hero pageTitle="About Us" />

      <section id="team" className="section-3 carousel showcase">
        <div className="overflow-holder">
          <div className="container">
            <div className="row items">
              <div className="col-md-6 item my-4">
                <span className="pre-title m-0">Behind the magic</span>

                <h2 className="my-1">Our Team</h2>
                <p className="m-0 ">
                  We employ national and international experts who specialize in
                  a variety of aviation specific disciplines. This specialized
                  expertise can be brought to bear on projects ranging from
                  small general aviation airports to national aviation system
                  plans.
                </p>
              </div>
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div
                    key={edge.node.id}
                    data-aos="fade-up"
                    className="col-md-6 item my-4"
                  >
                    <div className="row ">
                      <div className="col-5 p-0 pr-3 ">
                        <Link to={`/team${edge.node.fields.slug}`}>
                          <Img
                            fluid={
                              edge.node.frontmatter.profileImage.childImageSharp
                                .fluid
                            }
                            alt={edge.node.frontmatter.name}
                            className="person"
                          />
                        </Link>
                      </div>

                      <div className="col-7 text-left align-self-center">
                        <Link to={`/team${edge.node.fields.slug}`}>
                          <h4 className="pb-0 my-0">
                            {edge.node.frontmatter.name}
                          </h4>
                        </Link>
                        <p className="text-uppercase text-muted font-weight-bold  my-1">
                          {edge.node.frontmatter.position}
                        </p>
                        <p className="my-0 ">
                          {edge.node.frontmatter.description}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
